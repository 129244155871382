import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Engine, Scene, Color4, Color3, SceneLoader, ArcRotateCamera, Vector3, CubeTexture, Texture, PBRMaterial, DefaultRenderingPipeline, PostProcessRenderPipeline } from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import '@babylonjs/inspector';
import platform_ao from './assets/base_Ambient_Occlusion.png';
import background_ao from './assets/backgroundIllumination.png';
import tambor_interior_ai from './assets/tambor_interior_Illumination.png';
import { Frame, Page, PageEffectInfo, transform } from 'framer';
import logo from './assets/logo.png';
import shop from './assets/shop.svg';
import menu from './assets/menu.svg';
import zeppelin_logo from './assets/zeppelin_labs_logo.svg';

const platform_scene = require('./assets/scene.glb');
const loog_mini = require('./assets/loog_mini.glb');

const MARGIN = 30;

enum COLOR {
  RED = 'RED',
  GREEN = 'GREEN',
  BLACK = 'BLACK',
  PINK = 'PINK',
  WHITE = 'WHITE',
  YELLOW = 'YELLOW',

}

const AppWrapper = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  perspective: 1200px;

  .mobile-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .modal {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    flex-direction: column;
    z-index: 2;
    padding: 30px;
    pointer-events: none;
    box-shadow: 0 16px 200px 0px #000000a3;

    transform: translate3d(0, 100%, 0);
    transition: transform 0.5s cubic-bezier(.62,0,.37,.99);
    will-change: transform;

    user-select: none;

    .logo {

    }
    .informative {
      color: black;
      font-size: 14px;
      text-align: center;
      line-height: 1.8;
      margin-top: 20px;
      margin-bottom: 00px;
      max-width: 280px;
    }
    .lets-talk {
      color: black;
      font-size: 14px;
      font-weight: bold;
      padding: 20px;
    }
    .close {
      font-size: 14px;
      position: absolute;
      color: #FF4A4A;
      bottom: 20px;
      padding: 20px;
    }
  }

  .modal-active {
    transform: translate3d(0, 0%, 0);
    pointer-events: all;
  }

  canvas {
    width: 100%;
    height: 100%;
  }

  .pages {
    width: 100vw !important;
    height: 100vh !important;
  }

  .colors {
    z-index: 10000;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 33%;
    left: 7%;
    z-index: 1;
    pointer-events: all;

    .red {
      background: #A80122;
      box-shadow: 0px 0.65777px 0.57804px rgba(120, 43, 43, 0.0575202), 0px 1.4945px 1.31335px rgba(120, 43, 43, 0.0836187), 0px 2.60112px 2.28583px rgba(120, 43, 43, 0.102985), 0px 4.13211px 3.63125px rgba(120, 43, 43, 0.12), 0px 6.37554px 5.60275px rgba(120, 43, 43, 0.137015), 0px 9.94853px 8.74265px rgba(120, 43, 43, 0.156381), 0px 16.5161px 14.5142px rgba(120, 43, 43, 0.18248), 0px 33px 29px rgba(120, 43, 43, 0.24);
    }

    .black {
      background: #010101;
      box-shadow: 0px 0.65777px 0.57804px rgba(1, 1, 1, 0.0575202), 0px 1.4945px 1.31335px rgba(1, 1, 1, 0.0836187), 0px 2.60112px 2.28583px rgba(1, 1, 1, 0.102985), 0px 4.13211px 3.63125px rgba(1, 1, 1, 0.12), 0px 6.37554px 5.60275px rgba(1, 1, 1, 0.137015), 0px 9.94853px 8.74265px rgba(1, 1, 1, 0.156381), 0px 16.5161px 14.5142px rgba(1, 1, 1, 0.18248), 0px 33px 29px rgba(1, 1, 1, 0.24);
    }

    .green {
      background: #C9F5F1;
      box-shadow: 0px 0.65777px 0.57804px rgba(63, 114, 109, 0.0575202), 0px 1.4945px 1.31335px rgba(63, 114, 109, 0.0836187), 0px 2.60112px 2.28583px rgba(63, 114, 109, 0.102985), 0px 4.13211px 3.63125px rgba(63, 114, 109, 0.12), 0px 6.37554px 5.60275px rgba(63, 114, 109, 0.137015), 0px 9.94853px 8.74265px rgba(63, 114, 109, 0.156381), 0px 16.5161px 14.5142px rgba(63, 114, 109, 0.18248), 0px 33px 29px rgba(63, 114, 109, 0.24);
    }

    .pink {
      background: #EBB3D3;
      box-shadow: 0px 0.65777px 0.57804px rgba(119, 69, 98, 0.0575202), 0px 1.4945px 1.31335px rgba(119, 69, 98, 0.0836187), 0px 2.60112px 2.28583px rgba(119, 69, 98, 0.102985), 0px 4.13211px 3.63125px rgba(119, 69, 98, 0.12), 0px 6.37554px 5.60275px rgba(119, 69, 98, 0.137015), 0px 9.94853px 8.74265px rgba(119, 69, 98, 0.156381), 0px 16.5161px 14.5142px rgba(119, 69, 98, 0.18248), 0px 33px 29px rgba(119, 69, 98, 0.24);
    }

    .yellow {
      background: #FFE797;
      box-shadow: 0px 0.65777px 0.57804px rgba(144, 126, 63, 0.0575202), 0px 1.4945px 1.31335px rgba(144, 126, 63, 0.0836187), 0px 2.60112px 2.28583px rgba(144, 126, 63, 0.102985), 0px 4.13211px 3.63125px rgba(144, 126, 63, 0.12), 0px 6.37554px 5.60275px rgba(144, 126, 63, 0.137015), 0px 9.94853px 8.74265px rgba(144, 126, 63, 0.156381), 0px 16.5161px 14.5142px rgba(144, 126, 63, 0.18248), 0px 33px 29px rgba(144, 126, 63, 0.24);
    }

    .white {
      background: #F4F2F0;
      box-shadow: 0px 0.65777px 0.57804px rgba(164, 155, 147, 0.0575202), 0px 1.4945px 1.31335px rgba(164, 155, 147, 0.0836187), 0px 2.60112px 2.28583px rgba(164, 155, 147, 0.102985), 0px 4.13211px 3.63125px rgba(164, 155, 147, 0.12), 0px 6.37554px 5.60275px rgba(164, 155, 147, 0.137015), 0px 9.94853px 8.74265px rgba(164, 155, 147, 0.156381), 0px 16.5161px 14.5142px rgba(164, 155, 147, 0.18248), 0px 33px 29px rgba(164, 155, 147, 0.24);
    }

    button {
      width: 8vw;
      height: 8vw;
      max-width: 27px;
      max-height: 27px;
      border-radius: 100%;
      border: none;
      outline: none;
      margin-bottom: 6vmin;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(1);
      transition: transform 0.16s ease;
    }

    .selected {
      transform: scale(1.3);
    }
  }

  .card {
    background: transparent !important;
  }

  .ui {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    pointer-events: none;

    .tutorial {
      width: 50%;
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: 16%;
      animation-duration: 0.2s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      transform: scale(0);

      .arrow {
        position: absolute;
        border-radius: 3px;
        transform: rotate(45deg);
        background: white;
        width: 15px;
        height: 15px;
        top: -5px;
        margin: 0 auto;
        left: 0;
        right: 0;
      }

      .tutorial-text {
        background: white;
        font-size: 10px;
        color: black;
        padding: 10px;
        text-align: center;
        border-radius: 3px;
        box-shadow: 0 3px 15px -1px #0000001c;
      }
    }

    @keyframes tutorial-in {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }

    @keyframes tutorial-out {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }

    .add-to-cart {
      pointer-events: all;
      background: black;
      color: white;
      width: 93%;
      margin: 0 auto;
      left: 0;
      right: 0;
      display: block;
      bottom: 14px;
      position: absolute;
      padding: 12px;
      border: none;
      outline: none;
      border-radius: 5px;
      font-size: 13px;
      box-shadow: 0 5px 10px -1px #00000040;
    }

    .header {
      padding: 5%;
      height: 65px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      pointer-events: all;

      .logo {
        width: 57px;
      }
    }

    .guitar-menu {
      margin-top: 5vmin;
      width: 150vw;
    }

    .guitar-menu-item {
      color: #383838;
      text-align: center;
      display: inline-block;
      margin-right: ${MARGIN}px;

      h1 {
        font-family: "Old Standard TT";
        font-weight: normal;
        font-size: 8vmin;
        margin: 0;
        margin-bottom: 0.5rem;
      }
      p {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 900;
        letter-spacing: 0.145em;
        font-size: 4vmin;
        margin: 0;
        position: relative;

        &::after {
          content: '';
          width: 30%;
          height: 1px;
          background: black;
          bottom: 0;
          position: absolute;
          margin: 0 auto;
          left: 0;
          right: 0;
          bottom: -1rem;
        }
      }
    }
  }

  @media (min-width: 641px)  {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #cae9ff;

    .mobile-wrapper {
      height: 568px;
      width: 320px;
      position: relative;
      overflow: hidden;
      border-radius: 7px;
      box-shadow: 0 4px 45px -14px #2f3984e3
    }
    .pages {
      width: 320px !important;
      height: 568px !important;
    }
    .ui {
      .guitar-menu {
        width: 480px;
        margin-top: 3vmin;
      }
      .guitar-menu-item {
        h1 {
          font-size: 3vmin;
        }
        p { 
          font-size: 1.5vmin;
        }
      }
    }

    .colors {
      top: 36%;
      button {
        margin-bottom: 2vmin;
      }
    }
  }
`

const HEIGHT = 568;
const WIDTH = 320;
const SCREEN = 641;

const App: React.FC = () => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const firstItem = useRef<HTMLDivElement>(null);
  const secondItem = useRef<HTMLDivElement>(null);
  const guitarMenu = useRef<HTMLDivElement>(null);
  const [platform, setPlatform] = useState(undefined);
  const [miniColor, setMiniColor] = useState(COLOR.RED);
  const [currentPage, setCurrentPage] = useState(0);
  const tutorial = useRef<HTMLDivElement>();
  const [modal, setModal] = useState(false);

  let SCENE_GLOBAL = useRef<Scene>(null);

  const getWidth = () => {
    if (window.innerWidth > SCREEN) {
      return WIDTH;
    } else {
      return window.innerWidth
    }
  }

  const getHeight = () => {
    if (window.innerWidth > SCREEN) {
      return HEIGHT;
    } else {
      return window.innerHeight
    }
  }

  useEffect(() => {
    const engine = new Engine(canvas.current, true);
    SCENE_GLOBAL.current = new Scene(engine);
    const scene = SCENE_GLOBAL.current;
    scene.clearColor = Color4.FromColor3(Color3.Black());
    const camera = new ArcRotateCamera("camera", Math.PI / 2, 1.6, getWidth() <= 320 ? 170 : 150, new Vector3(0, getWidth() <= 320 ? 13 : 9, 0), scene);

    const standardPipeline = new PostProcessRenderPipeline(engine, "standardPipeline");
    scene.postProcessRenderPipelineManager.addPipeline(standardPipeline);
    scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("standardPipeline", camera);

    const hdrTexture = new CubeTexture("https://assets.babylonjs.com/environments/studio.env", scene);
    hdrTexture.gammaSpace = false;
    scene.environmentTexture = hdrTexture;
    scene.imageProcessingConfiguration.exposure = 1.2;
    scene.imageProcessingConfiguration.contrast = 1.2;

    SceneLoader.Append(platform_scene, "", scene, () => {
      const platformMaterial = scene.getMaterialByName("PlatformAO") as PBRMaterial;
      platformMaterial.ambientTexture = new Texture(platform_ao,
        scene,
        false,
        false);

      const backgroundMaterial = scene.getMaterialByName("Background") as PBRMaterial;
      backgroundMaterial.ambientTextureStrength = 1;
      backgroundMaterial.ambientTexture = new Texture(background_ao,
        scene,
        false,
        false);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const plt = scene.getTransformNodeByName("Platform");
      plt.rotationQuaternion = null;
      setPlatform(plt);

      const s_one = scene.getMeshByName("Sphere One");
      const s_two = scene.getMeshByName("Sphere Two");

      s_one.dispose();
      s_two.dispose();

      SceneLoader.ImportMesh(["Guitar"], loog_mini, "", scene, (meshes) => {
        const guitar = meshes[0];
        guitar.rotate(new Vector3(0, 1, 0), 0 * (Math.PI / 180));
        guitar.position.z = 2;
        guitar.setParent(plt);

        const tamborInteriorMaterial = scene.getMaterialByName("TamborInterior") as PBRMaterial;
        tamborInteriorMaterial.lightmapTexture = new Texture(tambor_interior_ai,
          scene,
          false,
          false);
        tamborInteriorMaterial.environmentIntensity = 0.20;
        tamborInteriorMaterial.albedoColor = new Color3(1.000, 0.239, 0.000);

        const guitar_two = meshes[0].clone("Guitar Two", plt);
        guitar_two.rotate(new Vector3(0, 1, 0), 180 * (Math.PI / 180));
        guitar_two.position.z = 2;

        setTimeout(() => {
          tutorial.current.style.animationName = "tutorial-in";
        }, 3000)

      });
    }, () => { }, (scene, message) => {
      console.log(message)
    })

    engine.runRenderLoop(() => {
      scene.render();
    });

    guitarMenu.current.style.transform = `translateX(${(getWidth() / 2) - firstItem.current.clientWidth / 2}px)`

  }, [])

  const scaleEffect = (info: PageEffectInfo) => {
    const { normalizedOffset } = info
    if (platform) {
      const original = (getWidth() / 2) - firstItem.current.clientWidth / 2;
      const secondpose = (getWidth() / 2) - firstItem.current.clientWidth - (secondItem.current.clientWidth / 2) - MARGIN;
      firstItem.current.style.opacity = transform(normalizedOffset, [-1, 0, 1.05, 2], [0, 0.2, 1, 1]).toString();
      secondItem.current.style.opacity = transform(normalizedOffset, [-1, 0, 1.05, 2], [1, 1, 0.2, 0]).toString();
      guitarMenu.current.style.transform = `translateX(${transform(normalizedOffset, [-1, 0, 1.05, 2], [secondpose - 20, secondpose, original, original + 20])}px)`
      platform.rotation.y = transform(normalizedOffset, [-1, 0, 1.05, 2], [250, 180, 0, -60], { clamp: true }) * (Math.PI / 180);
    }
    return {
      scale: Math.max(0.5, 1 + Math.min(0, normalizedOffset * -1))
    }
  }

  useEffect(() => {
    console.log("Cambio color")
    const tamborMaterial = SCENE_GLOBAL.current.getMaterialByName("Tambor") as PBRMaterial;
    if (tamborMaterial) {
      switch (miniColor) {
        case COLOR.RED:
          tamborMaterial.albedoColor = new Color3(0.529, 0.000, 0.000);
          break;
        case COLOR.BLACK:
          tamborMaterial.albedoColor = new Color3(0.000, 0.000, 0.000);
          break;
        case COLOR.GREEN:
          tamborMaterial.albedoColor = new Color3(0.412, 1.000, 0.878);
          break;
        case COLOR.PINK:
          tamborMaterial.albedoColor = new Color3(1.000, 0.463, 0.773);
          break;
        case COLOR.WHITE:
          tamborMaterial.albedoColor = new Color3(1.000, 0.914, 0.886);
          break;
        case COLOR.YELLOW:
          tamborMaterial.albedoColor = new Color3(1.000, 0.871, 0.349);
          break;
      }
    }
  }, [miniColor])

  const getMenuItems = () => {
    return [{
      name: "Loog Mini",
      price: "UY$ 3.990"
    },
    {
      name: "Loog Pro",
      price: "UY$ 3.990"
    }].map((item, index) => {
      return (
        <div className="guitar-menu-item" ref={index === 0 ? firstItem : secondItem}>
          <h1>{item.name}</h1>
          <p>{item.price}</p>
        </div>
      )
    })
  }

  return (
    <AppWrapper>
      <div className="mobile-wrapper">
        <div className={modal ? "modal modal-active" : "modal"}>
          <img className="logo" alt="Zeppelin Labs Logo" src={zeppelin_logo} />
          <p className="informative">This concept was created by Zeppelin, a full-service agency creating web & mobile UX for companies around the world. Got a project for us?</p>
          <a className="lets-talk" href="mailto:projects@zeppelinlabs.io"><u>¡Let’s talk!</u></a>
          <a className="close" onClick={() => setModal(false)}>Close modal</a>
        </div>
        <Page className="pages" onTapStart={() => { tutorial.current.style.animationName = "tutorial-out" }} effect={scaleEffect} currentPage={currentPage} onChangePage={(currentPage) => setCurrentPage(currentPage)}>
          <Frame className="card"></Frame>
          <Frame className="card"></Frame>
        </Page>
        <div className="ui">
          <div className="header" onClick={() => setModal(true)}>
            <img alt="Menu" src={menu} />
            <img className="logo" alt="Logo" src={logo} />
            <img alt="Shop" src={shop} />
          </div>
          <div className="guitar-menu" ref={guitarMenu}>
            {getMenuItems()}
          </div>
          <div className="colors">
            <button className={`red ${miniColor === COLOR.RED ? 'selected' : ''}`} onClick={() => setMiniColor(COLOR.RED)} />
            <button className={`white ${miniColor === COLOR.WHITE ? 'selected' : ''}`} onClick={() => setMiniColor(COLOR.WHITE)} />
            <button className={`pink ${miniColor === COLOR.PINK ? 'selected' : ''}`} onClick={() => setMiniColor(COLOR.PINK)} />
            <button className={`black ${miniColor === COLOR.BLACK ? 'selected' : ''}`} onClick={() => setMiniColor(COLOR.BLACK)} />
            <button className={`green ${miniColor === COLOR.GREEN ? 'selected' : ''}`} onClick={() => setMiniColor(COLOR.GREEN)} />
            <button className={`yellow ${miniColor === COLOR.YELLOW ? 'selected' : ''}`} onClick={() => setMiniColor(COLOR.YELLOW)} />
          </div>
          <button className="add-to-cart" onClick={() => setModal(true)}>ADD TO CART</button>
          <div className="tutorial" ref={tutorial}>
            <div className="arrow" />
            <div className="tutorial-text">
              Swipe to switch models
            </div>
          </div>
        </div>
        <canvas ref={canvas} />
      </div>
    </AppWrapper>
  );
}

export default App;